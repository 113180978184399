// src/Loading.js
import React from 'react';
import styles from './Loading.module.css';

const Loading = () => {
    return (
        <section className={styles.dots}>
            <span style={{ '--i': 1 }}></span>
            <span style={{ '--i': 2 }}></span>
            <span style={{ '--i': 3 }}></span>
            <span style={{ '--i': 4 }}></span>
            <span style={{ '--i': 5 }}></span>
            <span style={{ '--i': 6 }}></span>
            <span style={{ '--i': 7 }}></span>
            <span style={{ '--i': 8 }}></span>
            <span style={{ '--i': 9 }}></span>
            <span style={{ '--i': 10 }}></span>
        </section>
    );
};

export default Loading;

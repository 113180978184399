import {auth} from './firebaseConfig'
import {signInWithEmailAndPassword, signOut ,onAuthStateChanged, setPersistence, browserSessionPersistence, createUserWithEmailAndPassword} from "firebase/auth";
import { serverTimestamp } from 'firebase/firestore';
import {createDataUser} from "../components/getDataAccount";



const getData = (email,password)=>{
    //signInWithEmailAndPassword(auth, email, password)
    setPersistence(auth, browserSessionPersistence)
    .then(()=>{
        return signInWithEmailAndPassword(auth, email, password);
    })
    .then((userCredential) => {
    // Signed in 
    const user = userCredential.user;
    // ...
    })
    .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode,errorMessage);
    });

}

const logout = () => {
    signOut(auth).then(() => {
        console.log("User signed out successfully!");
    }).catch((error) => {
        console.error("Error signing out: ", error);
    });
};

const loginState = (callback)=>{
    onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      callback(true);
      // ...
    } else {
        callback(false);
      // User is signed out
      // ...
    }
    });
}

const register = async (name, lastName, email, address, houseNumber, col, cp, location, phone,church) => {
    const folioValue = 7 /*await folioContrato()*/;
    const folio = String(folioValue).padStart(6, '0');
    createUserWithEmailAndPassword(auth, email, folio)
        .then((userCredential) => {
            // Signed up 
            // Signed up 
            const userId = userCredential.user.uid;
            //const id = JSON.stringify(userId);
            const data = {
                id: folio,
                name: name.toUpperCase(),
                lastName: lastName.toUpperCase(),
                email: email,
                address: address.toUpperCase(),
                houseNumber: houseNumber,
                col: col.toUpperCase(),
                cp: cp,
                location: location.toUpperCase(),
                phone: phone,
                date: serverTimestamp(),
                church: church
            };
            createDataUser(data, userId);
            //queryContrato(folio);
            // ...
            // ...
        })
        .catch((error) => {
            //folioRest();
            const errorCode = error.code;
            console.log(errorCode);
            const errorMessage = error.message;
            console.log(`Error: ${errorMessage}`);
            window.alert(errorCode);
            window.alert(`Error: ${errorMessage}`);
            // ..
        });
}

const registerEmail = async (name, lastName, address, houseNumber, col, cp, location, phone,church) => {
    const folioValue = 5 /*await folioContrato()*/;
    const folio = String(folioValue).padStart(6, '0');

    const emailFolio = folio.slice(-3);
    const email = `${name.split(' ')[0].toLowerCase()}.${lastName.split(' ')[0].toLowerCase()}${emailFolio}@cpf.com`;

    createUserWithEmailAndPassword(auth, email, folio)
        .then((userCredential) => {
            // Signed up 
            // Signed up 
            const userId = userCredential.user.uid;
            //const id = JSON.stringify(userId);
            const data = {
                id: folio,
                name: name.toUpperCase(),
                lastName: lastName.toUpperCase(),
                email: email,
                address: address.toUpperCase(),
                houseNumber: houseNumber,
                col: col.toUpperCase(),
                cp: cp,
                location: location.toUpperCase(),
                phone: phone,
                date: serverTimestamp(),
                church: church
            };
            createDataUser(data, userId);
            //queryContrato(folio);
            // ...
            // ...
        })
        .catch((error) => {
            const errorCode = error.code;
            console.log(errorCode);
            const errorMessage = error.message;
            console.log(errorMessage);
            // ..
        });
}

export {getData, logout,loginState, registerEmail, register};
import { db } from "../firebase/firebaseConfig";
import { collection, doc, getDoc, getDocs, query, where, setDoc, serverTimestamp, updateDoc, increment } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { generateReceipt } from "./pdfGenerator";

const GetDataAccount = async (callback) => {
  const auth = getAuth();
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      const userId = user.uid;
      try {
        const docRef = doc(db, "user", userId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          callback(docSnap.data());
        } else {
          console.log("No such document!");
          console.log(docSnap);
        }
      } catch (error) {
        console.log("Error getting document:", error);
      }
    } else {
      console.log("No user is signed in");
    }
  });
};

const folioRecibo = async () => {
  try {
    const docRef = doc(db, 'folio', 'recibo'); // Referencia directa al documento

    // Incrementa el campo especificado en 1
    await updateDoc(docRef, {
      cont: increment(1)
    });

    // Obtén el documento actualizado
    const updatedDoc = await getDoc(docRef);

    if (updatedDoc.exists()) {
      const updatedData = updatedDoc.data();
      const folio = updatedData['cont'];
      return folio; // Retorna el valor actualizado
    } else {
      console.log('El documento no existe');
      return null;
    }
  } catch (error) {
    console.error('Error incrementando el campo: ', error);
    return null;
  }
};

const GetDataPayments = async (callback) => {
  const auth = getAuth();
  onAuthStateChanged(auth, async (user) => {
    if (user) {
      const userId = user.uid;
      try {
        const subCollectionRef = collection(db, "user", userId, "payments");
        const querySnapshot = await getDocs(subCollectionRef);
        const payments = querySnapshot.docs.map(doc => doc.data());
        callback(payments);
      } catch (error) {
        console.log("Error getting documents:", error.message);
      }
    } else {
      console.log("No user is signed in");
    }
  });
};

const Payment = async (id, name, lastName, payment, received, payment_id) => {
  try {
    const coleccionRef = collection(db, 'user');
    const q = query(coleccionRef, where('id', '==', id));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
      const subcollectionRef = collection(docRef, 'payments');
      
      // Verificar si el payment_id ya existe en la subcolección
      const paymentQuery = query(subcollectionRef, where('payment_id', '==', payment_id));
      const paymentSnapshot = await getDocs(paymentQuery);

      if (!paymentSnapshot.empty) {
        console.log('El payment_id ya existe en la subcolección');
        return 'El payment_id ya existe en la subcolección';
      }

      const folioValue = await folioRecibo(); // Espera a que se resuelva la función folio
      const newDocRef = doc(subcollectionRef, String(folioValue)); // Crea una referencia para el nuevo documento en la subcolección
      const data = {
        folio: folioValue,
        id: id,
        name: name.toUpperCase(),
        lastName: lastName.toUpperCase(),
        payment: payment,
        received: received.toUpperCase(),
        payment_id: payment_id,
        date: serverTimestamp() // Agrega el timestamp del servidor
      };

      await setDoc(newDocRef, data);

      //queryRecibo(id, folioValue);
      return folioValue;
    } else {
      console.log('No se encontró ningún documento con el ID proporcionado');
      return 'No se encontró ningún documento con el ID proporcionado';
    }
  } catch (error) {
    console.error('Error añadiendo documento a la subcolección: ', error);
    return 'Error añadiendo documento a la subcolección';
  }
};


const queryRecibo = async (id, payment_id) => {
  try {
    const coleccionRef = collection(db, 'user');
    const q = query(coleccionRef, where('id', '==', id));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const docRef = querySnapshot.docs[0].ref;
      const subcollectionRef = collection(docRef, 'payments');
      const qPayment = query(subcollectionRef, where('payment_id', '==', payment_id));
      const paymentSnapshot = await getDocs(qPayment);

      if (!paymentSnapshot.empty) {
        const docSnap = paymentSnapshot.docs[0];
        const data = docSnap.data();
        generateReceipt(data);
      } else {
        console.log('No se encontró el documento');
      }
    } else {
      console.log('No se encontró ningún documento con el ID proporcionado');
    }
  } catch (error) {
    console.error('Error consultando el documento: ', error);
  }
};

const createDataUser = async (data, id) => {
  try {
    await setDoc(doc(db, 'user', id), data);
  } catch (error) {
    console.error('Error añadiendo documento: ', error);
  }
};

export {GetDataAccount, GetDataPayments, Payment, queryRecibo, createDataUser};
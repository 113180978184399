import React, { useRef, useState } from "react";
import { getData } from "../firebase/auth";
import Loading from "./Loading";
import styles from "./login.module.css";


const Login = () => {
    const [loading, setLoading] = useState(false);
    const emailRef = useRef(null);
    const passwordRef = useRef(null);

    const onSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const email = emailRef.current.value;
        const password = passwordRef.current.value;
        await getData(email, password); 
        setLoading(false);
    };

    return (
        <section className={styles.mainContent}>
            <form className={styles.form} onSubmit={onSubmit}>
                <label className={styles.label}>Correo</label>
                <input type="email" placeholder="Correo" ref={emailRef} className={styles.input} />
                <label className={styles.label}>Contraseña</label>
                <input type="password" placeholder="Contraseña" ref={passwordRef} className={styles.input} />
                <button type="submit" className={styles.btn}>
                    {loading ? <Loading /> : <>Ingresar</>}
                </button>
            </form>
        </section>
    );
};

export default Login;
